import Layout from '../../components/Navigation/layout'
import * as React from "react";

const BasketPage = () => {
    return (
        <Layout pageTitle="Warenkorb">

        </Layout>
    )
}

export default BasketPage;